import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/senate-committee-commends-mgt/1.jpeg';
import blogd2 from '../../assets/images/2024/senate-committee-commends-mgt/2.jpeg';
import blogd3 from '../../assets/images/2024/senate-committee-commends-mgt/3.jpeg';
import blogd4 from '../../assets/images/2024/senate-committee-commends-mgt/4.jpeg';
import blogd5 from '../../assets/images/2024/senate-committee-commends-mgt/5.jpeg';
import blogd6 from '../../assets/images/2024/senate-committee-commends-mgt/6.jpeg';
import blogd7 from '../../assets/images/2024/senate-committee-commends-mgt/7.jpeg';
import blogd8 from '../../assets/images/2024/senate-committee-commends-mgt/8.jpeg';
import blogd9 from '../../assets/images/2024/senate-committee-commends-mgt/9.jpeg';
import blogd10 from '../../assets/images/2024/senate-committee-commends-mgt/10.jpeg';
import blogd11 from '../../assets/images/2024/senate-committee-commends-mgt/11.jpeg';
import blogd12 from '../../assets/images/2024/senate-committee-commends-mgt/12.jpeg';




import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                Senate Committee on Health Commends LUTH Management.
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Senate Committee on Health, led by  <strong>Senator 
                    Dr. Ipalibo Harry-Banigo</strong>, conducted an oversight visit to the 
                    Management of <strong>Lagos University Teaching Hospital (LUTH).</strong>{' '}
                  
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    {/* <img src={blogd3} height={370}/> */}
                    <p>
                    During the visit, Chief Medical Director Prof. Wasiu Lanre Adeyemo (FAS)
                     expressed gratitude for the support of the Senate, the House of 
                     Representatives, and the Federal Government in advancing the 
                     Hospital's infrastructure
                      <br />
                      <br />
                      Professor Adeyemo highlighted challenges faced by the Hospital, 
                      including Power Shortages, Manpower Shortages and others 
                    </p>
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                He appealed to the Senate for funds to support renewable energy 
                initiatives, emphasizing the critical role of Power in Hospital 
                operations.He also addressed outstanding payments to outsourced workers 
                  and the need to address Manpower shortages, which have affected 
                  the hospital's ability to meet Patient needs effectively.

                  <br />
                  <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                  <br />
                  Senator Banigo commended the Lagos University Teaching Hospital 
                  leadership for their dedication and pledged the committee's 
                  commitment to addressing the Hospital's challenges.She assured that the Committee would explore solutions to Power,
                   Outsourcing, and Manpower issues, and strive to facilitate the 
                   recruitment of additional Healthcare professionals.
                  <br />
                  <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                  <br />
                  The Committee's visit aimed to assess firsthand on the Hospital's 
                  needs and strategize on ways to enhance service delivery to Nigerians.
                </p>
                
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Fig 1: Residents at the department before the program */}
                  <br />
                  {/* Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach. */}
                </span>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd10} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Residents dispensing medications in advance for the outreach */}
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} width={500} />
                  </div>
                </div>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
                <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
